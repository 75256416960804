
export default class CommonUtils{

    static booleanify(value: any): boolean {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]
      
        return truthy.includes(value)
    }

}