import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
import { Category } from '../model/category';
import { Product } from '../model/product';
import { Cart } from '../model/cart';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_URL = environment.apiURL

  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any>{
    return this.http.post(`${this.API_URL}/login.php`, {
      username: username,
      password: password
    });
  }

  getAllUsers(): Observable<any> {
    return this.http.get(`${this.API_URL}/getAllUsers.php`);
  }

  getInforUser(email: string): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoUser.php`, {
      email: email,
    });
  }

  getCategories(): Observable<any> {
    return this.http.get(`${this.API_URL}/getCategories.php`);
  }

  getCategoriesById(): Observable<any> {
    return this.http.get(`${this.API_URL}/getCategoriesById.php`);
  }

  deleteCategory(id: number): Observable<any> {
    return this.http.post(`${this.API_URL}/deleteCategory.php`, {
      id: id,
    });
  }

  editCategory(category: Category): Observable<any> {
    return this.http.post(`${this.API_URL}/editCategory.php`, {
      id: category.id,
      titulo: category.titulo,
      descripcion: category.descripcion,
      orden: category.orden
    });
  }

  getProducts(): Observable<any> {
    return this.http.get(`${this.API_URL}/getProducts.php`);
  }

  getProductsByCategory(id: any): Observable<any> {
    return this.http.post(`${this.API_URL}/getProductsByCategory.php`, {
      id: id
    });
  }

  deleteProduct(id: number): Observable<any> {
    return this.http.post(`${this.API_URL}/deleteProduct.php`, {
      id: id,
    });
  }

  editCart(cart: Cart[], mesa: string | null): Observable<any> {
    return this.http.post(`${this.API_URL}/editCart.php`, {
      cart: JSON.stringify(cart),
      mesa: mesa
    });
  }

  getInfoCart(id: string): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoCart.php`, {
      id: id,
    });
  }

  getPedidos(): Observable<any> {
    return this.http.post(`${this.API_URL}/getPedidos.php`, {
    });
  }

  getPedido(id: string): Observable<any> {
    return this.http.post(`${this.API_URL}/getPedido.php`, {
      id: id,
    });
  }

  editPedido(id: string): Observable<any> {
    return this.http.post(`${this.API_URL}/editPedido.php`, {
      id: id,
    });
  }

  editProduct(product: Product): Observable<any> {
    return this.http.post(`${this.API_URL}/editProduct.php`, {
      id: product.id,
      titulo: product.titulo,
      descripcion: product.descripcion,
      referencia: product.referencia,
      precio: product.precio,
      festivo: product.festivo,
      categorias: product.categorias,
      alergeno_contiene_gluten: product.alergeno_contiene_gluten,
      alergeno_crustaceos: product.alergeno_crustaceos,
      alergeno_huevos: product.alergeno_huevos,
      alergeno_pescado: product.alergeno_pescado,
      alergeno_cacahuetes: product.alergeno_cacahuetes,
      alergeno_soja: product.alergeno_soja,
      alergeno_lacteos: product.alergeno_lacteos,
      alergeno_frutos_cascara: product.alergeno_frutos_cascara,
      alergeno_apio: product.alergeno_apio,
      alergeno_mostaza: product.alergeno_mostaza,
      alergeno_granos_sesamo: product.alergeno_granos_sesamo,
      alergeno_dioxido_sulfitos: product.alergeno_dioxido_sulfitos,
      alergeno_moluscos: product.alergeno_moluscos,
      alergeno_altramuces: product.alergeno_altramuces,
    });
  }

  upload(id: number): Observable<any>{
    return this.http.post(`${this.API_URL}/upload.php`, {
      id: id
    });
  }


  getMenu(): Observable<any> {
    return this.http.post(`${this.API_URL}/getMenu.php`, {
    });
  }


  editMenu(entrante: string, principal: string, secundario: string, postre: string, bebida: string, precio: string): Observable<any> {
    return this.http.post(`${this.API_URL}/editMenu.php`, {
      entrante: entrante,
      principal: principal,
      secundario: secundario,
      postre: postre,
      bebida: bebida,
      precio: precio
    });
  }
}
